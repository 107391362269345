<template>
  <div class="example">
    <apex-chart
      type="bar"
      height="150%"
      :options="chartOptions"
      :series="series"
    ></apex-chart>
  </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import { faker } from "@faker-js/faker";
Vue.use(VueApexCharts);
export default {
  components: {
    "apex-chart": VueApexCharts,
  },
  props: {
    pointData: {
      type: Object,
      default() {
        return {};
      },
    },
    isDetail: {
      type: Boolean,
      default: true,
    },
    toolBarShow: {
      type: Boolean,
      default: false,
    },
    titleName: {
      type: String,
      default: "",
    },
    xAxisName: {
      type: String,
      default: "xAxis",
    },
    yAxisName: {
      type: String,
      default: "yAxis",
    },
  },
  data() {
    return {
      series: [],
      chartOptions: {},
    };
  },
  methods: {
    async getData() {
      const data = [];
      for (const key in this.pointData) {
        const value = this.pointData[key];
        data.push(this.formatDataPoint(key, value));
      }
      this.addSeries(data, "series1");
    },
    setChartConfig() {
      this.chartOptions = {
        chart: {
          toolbar: {
            show: false,
          },
          height: 350,
          type: "bar",
          zoom: {
            enabled: true,
          },
          animations: {
            enabled: false,
            speed: 500,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: 3,
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        // dataLabels: {
        //   formatter: function (val, opt) {
        //     const goals =
        //       opt.w.config.series[opt.seriesIndex].data[opt.dataPointIndex]
        //         .goals;
        //     if (goals && goals.length) {
        //       return `${val} / ${goals[0].value}`;
        //     }
        //     return val;
        //   },
        // },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          show: false,
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
        },
        xaxis: {
          type: "numeric",
          title: {
            text: this.xAxisName,
            offsetY: 0,
          },
        },
        yaxis: {
          type: "numeric",
          title: {
            text: this.yAxisName,
          },
          labels: {
            formatter: function(value) {
              if (typeof value === "number") {
                if (Math.abs(value) >= 1e6) {
                  // Convert to scientific notation if value exceeds 6 digits
                  return value.toExponential(1);
                }
                return value.toFixed(1);
              } else {
                return value;
              }
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val;
            },
          },
        },
      };
    },
    changeStyle(isDetail, toolBarShow) {
      this.chartOptions.chart.toolbar.show = toolBarShow;
      this.chartOptions.xaxis.show = isDetail;
      this.chartOptions.yaxis.show = isDetail;
      this.chartOptions.grid.show = isDetail;
      if (isDetail == false) {
        this.chartOptions.xaxis.title.text = "";
        this.chartOptions.title.text = "";
        this.chartOptions.grid.row.colors = undefined;
        this.chartOptions.grid.column.colors = undefined;
      }
    },
    setChartInfo(titleName, xAxisName, yAxisName) {
      this.chartOptions.title.text = titleName;
      this.chartOptions.xaxis.title.text = xAxisName;
      this.chartOptions.yaxis.title.text = yAxisName;
    },
    addSeries(data, name) {
      this.series.push({ data: data });
      this.series[0].name = name;
    },
    createRandomUser() {
      return {
        x: faker.datatype.number(),
        y: 500,
        goals: [
          {
            name: "Average",
            value: 500,
            strokeWidth: 0,
            strokeHeight: 5,
            strokeLineCap: "round",
            strokeColor: "#775DD0",
          },
        ],
      };
    },
    formatDataPoint(key, value) {
      return {
        x: key,
        y: value,
        goals: [
          {
            name: "Average",
            value: value,
            strokeWidth: 0,
            strokeHeight: 5,
            strokeLineCap: "round",
            strokeColor: "#775DD0",
          },
        ],
      };
    },
  },
  mounted() {
    this.getData();
  },
  created() {
    this.setChartConfig();
    this.setChartInfo(this.titleName, this.xAxisName, this.yAxisName);
    this.changeStyle(this.isDetail, this.toolBarShow);
  },
};
</script>
