<script>
import { Line, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ["chartData", "options"],
  computed: {
    // language() {
    //   return this.$store.state.settings.language;
    // },
  },
  watch: {
    // language() {
    //   this.renderChart(this.chartData, this.options);
    // },
    chartData() {
      this.renderChart(this.chartData, this.options);
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
