<template>
  <apex-chart
    ref="chart"
    type="bar"
    :height="height"
    :options="chartOptions"
    :series="series"
  ></apex-chart>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
import { faker } from "@faker-js/faker";
Vue.use(VueApexCharts);
export default {
  components: {
    "apex-chart": VueApexCharts,
  },
  props: {
    // pointData: {
    //   type: Array,
    //   default() {
    //       return {};
    //   },
    // },
    isDetail: {
      type: Boolean,
      default: true,
    },
    toolBarShow: {
      type: Boolean,
      default: false,
    },
    titleName: {
      type: String,
      default: "barChart",
    },
    xAxisName: {
      type: String,
      default: "xAxis",
    },
    yAxisName: {
      type: String,
      default: "yAxis",
    },
    height: {
      default: "auto",
    },
  },
  data() {
    return {
      // series: [
      //   {
      //     name: "Positive",
      //     data: [
      //       0, 0.65, 0.76, 0.88, 1.0, 0.8, 0.6, 0.8, 0.9, 0.2, 0, 0.3, 0.1,
      //       0.2, 0.5, 0.9, 0.5, 0.3,
      //     ],
      //   },
      //   {
      //     name: "Negative",
      //     data: [
      //       -0.8, -0.05, -0.06, -0.18, -0.4, -0.2, -0.85, -0.7, -0.96, -0.22,
      //       -0.3, -0.4, -0.1, -4, -0.1, -0.4, -0.1, -0.8,
      //     ],
      //   },
      // ],
      series: [],
      categories: [],

      chartOptions: {},
    };
  },
  methods: {
    async getData() {
      // const data = [];
      // Array.from({ length: 100 }).forEach(() => {
      //   data.push(this.createRandomUser());
      // });
      // this.addSeries(data, "series1");
    },
    setChartConfig() {
      this.chartOptions = {
        chart: {
          toolbar: {
            show: false,
          },
          height: "100%",
          type: "bar",
          stacked: true,

          zoom: {
            enabled: false,
          },
          animations: {
            enabled: false,
            speed: 500,
          },
          events: {
            click: (event, chartContext, config) => {
              //  console.log(config.config.xaxis.categories[[config.dataPointIndex]]);
              let selectCategory =
                config.config.xaxis.categories[[config.dataPointIndex]];
              let categoryList = config.config.xaxis.categories;
              this.$emit("selectCategory", categoryList, selectCategory);
            },
          },
        },
        // colors: ["#008FFB", "#FF4560"],
        colors: ["#76b852", "#ee4f4f", "#9E9E9E"],

        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: "80%",
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          show: false,
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
        },
        xaxis: {
          tickAmount: 4,
          min: 0,
          max: 1,

          categories: [],
        },
        yaxis: {
          type: "numeric",
          title: {
            text: this.yAxisName,
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val;
            },
          },
        },
      };
    },
    changeStyle(isDetail, toolBarShow) {
      this.chartOptions.chart.toolbar.show = toolBarShow;
      this.chartOptions.xaxis.show = isDetail;
      this.chartOptions.yaxis.show = isDetail;
      this.chartOptions.grid.show = isDetail;
      if (isDetail == false) {
        this.chartOptions.xaxis.title.text = "";
        this.chartOptions.title.text = "";
        this.chartOptions.grid.row.colors = undefined;
        this.chartOptions.grid.column.colors = undefined;
      }
    },
    setChartInfo(titleName) {
      this.chartOptions.title.text = titleName;
      //   this.chartOptions.xaxis.title.text = xAxisName;
      //   this.chartOptions.yaxis.title.text = yAxisName;
    },
    clearData() {
      this.series = [];
      this.categories = [];
    },
    addSeries(data, name) {
      this.series.push({ name: name, data: data });
    },
    getSeriesLength() {
      return this.series.length;
    },
    addCategory(categories) {
      this.categories = categories;
    },
    createRandomUser() {
      return {
        x: faker.datatype.number(),
        y: faker.datatype.number(),
      };
    },
    update() {
      this.$nextTick(() => {
        this.$refs.chart.updateOptions({
          series: this.series,
          xaxis: {
            categories: this.categories,
          },
        });
      });
    },
  },
  mounted() {
    // this.getData();
  },
  created() {
    this.setChartConfig();
    this.setChartInfo(this.titleName);
    // this.changeStyle(this.isDetail, this.toolBarShow);
  },
  watch: {
    categories: {
      handler() {
        this.update();
      },
      immediate: true,
    },
  },
};
</script>
