<template>
  <div style="width: -webkit-fill-available">
    <v-app-bar>
      <v-toolbar-title>Causal Graph </v-toolbar-title>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" id="cauasl_graph_guide">
            mdi-information-outline
          </v-icon>
        </template>
        Show tour guide
      </v-tooltip>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        placeholder="Node Name"
      ></v-text-field>

      <v-spacer></v-spacer>

      <div id="toolbar">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :href="chatbotURL"
              target="_blank"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-chat-processing-outline</v-icon>
            </v-btn></template
          >
          <span>Chatbot</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :href="causalPredictionURL"
              target="_blank"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-creation-outline</v-icon>
            </v-btn></template
          >
          <span>Causal Prediction</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="zoomIn" v-bind="attrs" v-on="on">
              <v-icon>mdi-magnify-plus-outline</v-icon>
            </v-btn></template
          >
          <span>Zoom in</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }"
            ><v-btn icon @click="zoomOut" v-bind="attrs" v-on="on">
              <v-icon>mdi-magnify-minus-outline</v-icon>
            </v-btn>
          </template>
          <span>Zoom out</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="fitCenter" v-bind="attrs" v-on="on">
              <v-icon>mdi-fit-to-page-outline</v-icon>
            </v-btn>
          </template>
          <span>Fit center</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="fitView" v-bind="attrs" v-on="on">
              <v-icon>mdi-image-filter-center-focus</v-icon>
            </v-btn>
          </template>
          <span>Fit view</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="onClickUndo" v-bind="attrs" v-on="on">
              <v-icon>mdi-undo</v-icon>
            </v-btn>
          </template>
          <span>Undo</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="onClickRedo" v-bind="attrs" v-on="on">
              <v-icon>mdi-redo</v-icon>
            </v-btn>
          </template>
          <span>Redo</span>
        </v-tooltip>

        <!-- <upload-dialog
          v-if="isEdit"
          ref="upload"
          title="Causal Graph Import"
          @onUpload="uploadData"
          :uploadFinish="uploadFinish"
        >
        </upload-dialog>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="downloadData" v-bind="attrs" v-on="on">
              <v-icon>mdi-download</v-icon>
            </v-btn>
          </template>
          <span>Download causal graph</span>
        </v-tooltip> -->

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="isEdit"
              icon
              @click="saveData"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </template>
          <span>Save causal graph edition</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="isEdit"
              icon
              @click="onShowDialogReInferConfirmation"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-play-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>Re-infer causal graph</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon @click="captureImage" v-bind="attrs" v-on="on">
              <v-icon>mdi-camera-outline</v-icon>
            </v-btn>
          </template>
          <span>Capture image</span>
        </v-tooltip>
      </div>
    </v-app-bar>
    <v-sheet height="85vh" class="overflow-hidden" style="position: relative">
      <!-- drawer-->
      <v-navigation-drawer
        :app="false"
        absolute
        right
        v-model="drawer_right"
        :width="barWidth"
        mobile-breakpoint="0"
        class="grey lighten-5"
      >
        <v-row justify="center">
          <v-expansion-panels accordion v-model="panel" multiple>
            <v-expansion-panel>
              <v-expansion-panel-header
                >Information_{{ this.selectType }}</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-card-text>
                  <div v-if="this.selectType == 'node'">
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Features</th>
                            <th class="text-left">Value</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="item in inforArr" :key="item.feature">
                            <td>{{ item.feature }}</td>
                            <td>{{ item.value }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-spacer></v-spacer>
                    <div v-if="this.uniqueArr !== null" class="mt-4 pl-4">
                      Unique
                    </div>
                    <v-simple-table
                      dense
                      v-if="uniqueArr !== null"
                      fixed-header
                      height="200px"
                    >
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">Name</th>
                            <th class="text-left">Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="o in uniqueArr" :key="o.name">
                            <td>{{ o.name }}</td>
                            <td>{{ o.count }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>

                  <!-- show responsieve curve with numerical to nemerical data -->
                  <div
                    v-if="
                      this.selectType == 'edge' &&
                        this.selectEdgeType === 'numericalToNumerical'
                    "
                  >
                    <line-chart
                      v-if="this.selectType == 'edge'"
                      :series="series"
                      :zoomEnable="false"
                      :titleName="`Correlation   m: ${this.co}`"
                      :xAxisName="edgeSourceName"
                      :yAxisName="edgeTargetName"
                      dateTime="numeric"
                      height="300"
                    >
                    </line-chart>
                  </div>

                  <!-- show lollipop chart with numerical to categorical data -->
                  <div
                    v-else-if="
                      this.selectType == 'edge' &&
                        this.selectEdgeType === 'categoricalToNumerical'
                    "
                  >
                    <lollipop-chart
                      :pointData="lollipopPoints"
                      :xAxisName="edgeSourceName"
                      :yAxisName="edgeTargetName"
                    ></lollipop-chart>
                  </div>

                  <!-- show multi_line_chart -->
                  <div
                    v-else-if="
                      this.selectType === 'edge' &&
                        this.selectEdgeType === 'numericalToCategorical'
                    "
                  >
                    <MultiLine
                      :chartData="multiLines"
                      :options="options"
                    ></MultiLine>
                  </div>
                  <!-- show none with categorical to categorical data -->
                  <div
                    v-else-if="
                      this.selectType == 'edge' && this.selectEdgeType === null
                    "
                  >
                    No chart available
                  </div>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="this.selectType == 'node'">
              <v-expansion-panel-header>Note</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-text>
                  <v-textarea
                    outlined
                    height="100%"
                    name="input-7-4"
                    label="Note"
                    v-model="description"
                  ></v-textarea>
                </v-card-text>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="this.selectType == 'node'">
              <v-expansion-panel-header>Importance</v-expansion-panel-header>
              <v-expansion-panel-content>
                <bar-negative-chart
                  v-if="this.selectType == 'node'"
                  ref="barNegativeChart"
                  titleName=""
                  yAxisName=""
                  xAxisName=""
                  :height="150 + importanceLength * 30"
                  @selectCategory="selectCategory"
                ></bar-negative-chart>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-navigation-drawer>

      <!-- drawer button-->
      <v-btn
        :class="
          drawer_right
            ? 'drawer_right_Button_open'
            : 'drawer_right_Button_close'
        "
        color="primary"
        fab
        x-small
        dark
        @click.stop="right_drawerChange"
      >
        <v-icon>{{ drawer_right_icon }} </v-icon>
      </v-btn>

      <!-- causal graph-->
      <v-container fluid>
        <causal-chart
          ref="causal"
          @renderFinish="getuploadState"
          @targetNode="renderNodeProfile"
          @targetNodeDesc="writeDescription"
          @targetEdge="renderEdgeProfile"
          @nodeImportance="getImportance"
          :nodeSelect="nodeSelect"
          :description="description"
        ></causal-chart>
      </v-container>
    </v-sheet>
    <!-- create save alert dialog -->
    <v-dialog v-model="dialogExitAlert" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Exit the Causal Discovery Editor?</span>
        </v-card-title>
        <v-card-text>
          If you modify the graph, please remember to save the latest edition.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseExit"> Close </v-btn>
          <v-btn color="blue darken-1" text @click="onConfirmExit">
            Exit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- re-infer confirmation dialog -->
    <v-dialog v-model="dialogReInferConfirmation" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Re-infer Causal Graph</span>
        </v-card-title>
        <v-card-text>
          Save the latest model before re-inferring the causal graph. The
          process may take a few minutes for the results.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="onCloseReInferConfirmation">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="generateGraph">
            Re-infer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import data from "@/models/data";
import { faker } from "@faker-js/faker"; // generate fake data
import LineChart from "@/components/chart/ApexChart/LineChart.vue";
import LollipopChart from "@/components/chart/ApexChart/LollipopChart.vue";
import BarNegativeChart from "@/components/chart/ApexChart/BarNegativeChart.vue";
import CausalChart from "./DatasetGroupsCausalGraph.vue";
import _ from "lodash";
// import UploadDialog from "@/components/atoms/UploadDialog.vue";
import MultiLine from "@/components/chart/MultiLine";
// import moment from "moment";

export default {
  name: "HomeView",
  components: {
    BarNegativeChart,
    CausalChart,
    // UploadDialog,
    LineChart,
    LollipopChart,
    MultiLine,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    search: "",
    panel: [0, 2],
    importanceLength: 0,
    uploadFinish: false,
    barWidth: "30%",
    drawer_left: false,
    drawer_right: false,
    drawer_down: false,
    drawer_left_icon: "mdi-chevron-right",
    drawer_right_icon: "mdi-chevron-left",
    drawer_down_icon: "mdi-chevron-down",
    nodeSelect: "",
    edgeSelect: "",
    selectType: "",
    selectEdgeType: null,
    description: "",
    nodeData: [], // node information
    edgeSource: "",
    edgeTarget: "",
    edgeSourceName: "",
    edgeTargetName: "",
    // correlationPoints: [], // responsive points
    series: [], // responsive points
    lollipopPoints: [],
    inforArr: null,
    uniqueArr: null,
    importanceArr: null,
    co: null,
    nextRoute: null,
    dialogExitAlert: false,
    leave: null,
    errorAlert: {
      code: null,
      message: null,
    },
    dialogReInferConfirmation: false,
    is_acyclic: false,
    multiLines: null,
  }),
  methods: {
    //--------------------------------
    //  x left drawer button
    //--------------------------------
    left_drawerChange() {
      this.drawer_left = !this.drawer_left;
      if (this.drawer_left == true) {
        this.drawer_left_icon = "mdi-chevron-left";
      } else {
        this.drawer_left_icon = "mdi-chevron-right";
      }
    },
    //--------------------------------
    // right drawer button
    //--------------------------------
    right_drawerChange() {
      this.drawer_right = !this.drawer_right;
      if (this.drawer_right == true) {
        this.drawer_right_icon = "mdi-chevron-right";
        // this.$refs.causal.refreshGraph("open",this.barWidth);
      } else {
        this.drawer_right_icon = "mdi-chevron-left";
        // this.$refs.causal.refreshGraph("close",this.barWidth);
      }
    },
    //--------------------------------
    //  x down drawer button
    //--------------------------------
    down_drawerChange() {
      this.drawer_down = !this.drawer_down;
      if (this.drawer_down == true) {
        this.drawer_down_icon = "mdi-chevron-up";
      } else {
        this.drawer_down_icon = "mdi-chevron-down";
      }
    },

    //--------------------------------
    // ToolBar-save latest causal graph
    //--------------------------------
    async saveData() {
      this.$refs.causal.saveGraph();
      let id = this.$route.params.id;
      if (!id) {
        id = this.$route.query.datasetGroupId;
      }
      const payload = {
        datasetGroupId: id,
        nodes: this.$refs.causal.getNodesForUpdateDAG(),
        edges: this.$refs.causal.getEdgesForUpdateDAG(),
      };
      try {
        await data.updateCausalGraph(payload);
      } catch (err) {
        this.errorAlert.message = err.response.data.message
          ? err.response.data.message
          : "System error. Please contact the developer.";
        this.$dialog.message.error(this.errorAlert.message, {
          position: "top",
        });
      }
    },

    //--------------------------------
    // ToolBar-training causal graph
    //--------------------------------
    async generateGraph() {
      //save the current json to the DB
      let id = this.$route.params.id;
      if (!id) {
        id = this.$route.query.datasetGroupId;
      }
      const payload = {
        datasetGroupId: id,
      };
      try {
        const res = await data.createCausalGraph(payload);
        if (res) {
          this.leave = true;
          this.$router.push({
            path: `/datasetGroups/${id}/overview`,
            query: {
              isProcessingCausality: true,
              datasetGroupGraph: "{}",
            },
          });
        }
      } catch (err) {
        this.errorAlert.message = err.response.data.message
          ? err.response.data.message
          : "System error. Please contact the developer.";
        this.$dialog.message.error(this.errorAlert.message, {
          position: "top",
        });
      }
    },
    //--------------------------------
    // ToolBar-download graph.json
    //--------------------------------
    downloadData() {
      this.$refs.causal.downloadData();
    },
    //--------------------------------
    // ToolBar-upload graph.json
    //--------------------------------
    uploadData(file) {
      this.$refs.causal.uploadJson(file);
    },
    getuploadState(value) {
      if (value == true) {
        this.uploadFinish = true;
        this.$refs.upload.onClose();
      } else {
        this.uploadFinish = false;
      }
    },
    captureImage() {
      this.$refs.causal.captureImage();
    },
    zoomIn() {
      this.$refs.causal.zoomIn();
    },
    zoomOut() {
      this.$refs.causal.zoomOut();
    },
    fitCenter() {
      this.$refs.causal.fitCenter();
    },
    fitView() {
      this.$refs.causal.fitView();
    },
    onClickUndo() {
      this.$refs.causal.undo();
    },
    onClickRedo() {
      this.$refs.causal.redo();
    },

    //--------------------------------
    // x get fake data via project name
    //--------------------------------
    async getNodeData(id) {
      const inforArr = [];
      // Reset data
      this.uniqueArr = null;
      try {
        let datasetGroupId = this.$route.params.id;
        if (!datasetGroupId) {
          datasetGroupId = this.$route.query.datasetGroupId;
        }
        const payload = {
          datasetGroupId: datasetGroupId,
          id: id,
        };
        const response = await data.getCausalGraphNodes(payload);
        this.nodeData = response.nodes;
        if (response) {
          // API
          let node = this.nodeData.find((element) => {
            return element.id === id;
          });

          // Static data
          // let node = {
          //   id: "82b06defa46b71506709562e4ed506eb",
          //   is_ext: false,
          //   label: "region",
          //   desc: "This is a description about region",
          //   details: {
          //     datatype: "category",
          //     statistics: {
          //       total: 800,
          //       empty: 5,
          //       valid: 795,
          //       uniques: [
          //         {
          //           value: "usa",
          //           num: 135,
          //         },
          //         {
          //           value: "mexico",
          //           num: 246,
          //         },
          //         {
          //           value: "japan",
          //           num: 414,
          //         },
          //         {
          //           value: "usa",
          //           num: 135,
          //         },
          //         {
          //           value: "mexico",
          //           num: 246,
          //         },
          //         {
          //           value: "japan",
          //           num: 414,
          //         },
          //         {
          //           value: "usa",
          //           num: 135,
          //         },
          //         {
          //           value: "mexico",
          //           num: 246,
          //         },
          //         {
          //           value: "japan",
          //           num: 414,
          //         },
          //         {
          //           value: "usa",
          //           num: 135,
          //         },
          //         {
          //           value: "mexico",
          //           num: 246,
          //         },
          //         {
          //           value: "japan",
          //           num: 414,
          //         },
          //       ],
          //       // num_of_uniques: 3,
          //       num_of_uniques: 12,
          //     },
          //   },
          //   analysis: [
          //     {
          //       from: "6defa46b71506709562e4ed506eb82b0",
          //       datatype: "numeric",
          //       imp: 0.838117891,
          //       co: 0.8391776352,
          //     },
          //   ],
          // };

          // let { Info, DataType, Summary } = response;
          let DataType = node.details.datatype;
          let Summary = {
            empty: node.details.statistics.empty,
            value: node.details.statistics.value,
          };
          let Info = node.details.statistics;
          if (DataType == "character") {
            console.log(Summary);
          } else if (DataType == "numeric" || DataType == "integer") {
            inforArr.push({ feature: "Name", value: node.label });
            inforArr.push({ feature: "Datatype", value: DataType });
            // for (const property in Summary) {
            //   inforArr.push({
            //     feature: property,
            //     value: Summary[property],
            //   });
            // }
            for (const property in Info) {
              inforArr.push({ feature: property, value: Info[property] });
            }
          } else if (DataType === "category" || DataType === "text") {
            inforArr.push({ feature: "Name", value: node.label });
            inforArr.push({ feature: "Datatype", value: DataType });
            inforArr.push({ feature: "Total", value: Info["total"] });
            inforArr.push({ feature: "Empty", value: Info["empty"] });
            inforArr.push({ feature: "Valid", value: Info["valid"] });
            inforArr.push({
              feature: "Unique count",
              value: Info["num_of_uniques"] ? Info["num_of_uniques"] : "N/A",
            });
            if (Info["num_of_uniques"] < 100) {
              let values = [];
              for (const i of Info.uniques) {
                values.push({ name: i.value, count: i.num });
              }
              this.uniqueArr = values;
            }
          } else {
            console.log(Summary);
          }
          // Update importance
          this.updateImportance();
        }
      } catch (error) {
        console.log(error);
      }
      this.inforArr = inforArr;
    },

    //--------------------------------
    // draw responsive curve
    // correlationPoint[[x1,y1],[x2,y2]]
    //--------------------------------
    async getResponseCurve() {
      // Reset
      let correlationPoints = [];
      this.selectEdgeType = null;

      // Array.from({ length: 2 }).forEach(() => {
      //   this.correlationPoints.push(this.createRandom());
      // });
      try {
        let datasetGroupId = this.$route.params.id;
        if (!datasetGroupId) {
          datasetGroupId = this.$route.query.datasetGroupId;
        }
        const payload = {
          datasetGroupId: datasetGroupId,
          x: this.edgeSource,
          y: this.edgeTarget,
        };
        const response = await data.getCausalGraphEdge(payload);
        if (response) {
          // const curve = response.curves.find((curve) => {
          //   return (
          //     curve.from === this.edgeSource && curve.to === this.edgeTarget
          //   );
          // });
          // console.log(`curve: ${curve}`);
          // console.log(curve);
          // this.correlationPoints = _.cloneDeep(curve.points);

          // API
          const edge = response.edges[0];
          // const edge = {
          //   from: "71506709562e4ed506eb82b06defa46b",
          //   to: "82b06defa46b71506709562e4ed506eb",
          //   x: "official website",
          //   y: "region",
          //   imp: 0.5671891031,
          //   co: null,
          //   chart: {
          //     style: "multi_line_chart",
          //     multi_line_chart: {
          //       x: [1.2, 3.4, 5.6, 321.6, 323.8],
          //       y: [
          //         {
          //           name: "usa",
          //           values: [
          //             0.696105361,
          //             0.6955412626,
          //             0.6945163012,

          //             0.0023371722,
          //             0.0023372734,
          //           ],
          //         },
          //         {
          //           name: "korea",
          //           values: [
          //             0.2953289747,
          //             0.2952950299,
          //             0.2952648401,

          //             0.0012921571,
          //             0.0012931341,
          //           ],
          //         },
          //         {
          //           name: "japan",
          //           values: [
          //             0.0085656736,
          //             0.0091637112,
          //             0.0102188597,

          //             0.9963706732,
          //             0.9963696003,
          //           ],
          //         },
          //       ],
          //     },
          //   },
          // };

          // Static data
          // const edge = {
          //   from: "82b06defa46b71506709562e4ed506eb",
          //   to: "506709562e4ed506eb82b06defa46b71",
          //   x: "region",
          //   y: "sales",
          //   imp: 0.9103156718,
          //   co: -0.329572321,
          //   bar_graph: {
          //     usa: 324.5,
          //     korea: 23.5,
          //     japan: 12.3,
          //   },
          // };

          if (edge.chart.style === "response_curve") {
            console.log("response_curve");
            // this.correlationPoints = _.cloneDeep(edge.chart.response_curve);
            correlationPoints = _.cloneDeep(edge.chart.response_curve);
            this.series = [
              {
                name: "series1",
                data: correlationPoints,
              },
            ];

            this.edgeSourceName = edge.x;
            this.edgeTargetName = edge.y;
            this.co = edge.co.toFixed(3);
            this.selectEdgeType = "numericalToNumerical";
          } else if (edge.chart.style === "bar_chart") {
            console.log("bar_chart");
            let lollipopPoints = edge.chart.bar_chart;

            // Convert the object into an array of key-value pairs
            const dataArray = Object.entries(edge.chart.bar_chart);

            // Sort the array of key-value pairs by values in descending order
            const sortedArray = _.orderBy(dataArray, ["1"], ["desc"]);

            // Take the top 10 values from the sorted array
            const top10Values = _.take(sortedArray, 10);

            // If you want the result as an object, you can convert it back from the array
            const top10Object = _.fromPairs(top10Values);
            lollipopPoints = _.fromPairs(
              _.orderBy(Object.entries(top10Object), ["1"], ["desc"])
            );

            // console.log(lollipopPoints);
            this.lollipopPoints = _.cloneDeep(lollipopPoints);
            this.edgeSourceName = edge.x;
            this.edgeTargetName = edge.y;
            this.selectEdgeType = "categoricalToNumerical";
          } else if (edge.chart.style === "multi_line_chart") {
            console.log("multi_line_chart");
            //         const lineChartData = {
            //   labels: chartObjArray.map((d) => d.time_stamp),
            //   datasets: [
            //     {
            //       label: actual_legend,
            //       data: actualData,
            //       fill: false,
            //       borderColor: "#0DD4EB",
            //       backgroundColor: "#0DD4EB",
            //     },
            //     {
            //       label: forecast_legend,
            //       data: forecastData,
            //       fill: false,
            //       borderColor: "#E76F86",
            //       backgroundColor: "#E76F86",
            //     },
            //   ],
            // };

            //15 colors
            let colors = [
              "#ef476f",
              "#ffd166",
              "#06d6a0",
              "#118ab2",
              "#073b4c",
              "#ff6b35",
              "#f7c59f",
              "#efefd0",
              "#004e89",
              "#1a659e",
              "#780000",
              "#c1121f",
              "#669bbc",
              "#788bff",
              "#9bb1ff",
            ];

            let multiLines = edge.chart.multi_line_chart;
            // let multiLines = {
            //   x: [1.2, 3.4, 5.6, 321.6, 323.8],
            //   y: [
            //     {
            //       name: "usa",
            //       values: [
            //         0.696105361,
            //         0.6955412626,
            //         0.6945163012,
            //         0.0023371722,
            //         0.0023372734,
            //       ],
            //     },
            //     {
            //       name: "korea",
            //       values: [
            //         0.2953289747,
            //         0.2952950299,
            //         0.2952648401,
            //         0.0012921571,
            //         0.0012931341,
            //       ],
            //     },
            //     {
            //       name: "japan",
            //       values: [
            //         0.0085656736,
            //         0.0091637112,
            //         0.0102188597,
            //         0.9963706732,
            //         0.9963696003,
            //       ],
            //     },
            //   ],
            // };
            this.multiLines = {
              labels: multiLines.x,
              datasets: multiLines.y.map((o, idx) => {
                let colorIndex = idx % colors.length;

                return {
                  label: o.name,
                  data: o.values,
                  fill: false,
                  borderColor: colors[colorIndex],
                  backgroundColor: colors[colorIndex],
                };
              }),
            };
            this.edgeSourceName = edge.x;
            this.edgeTargetName = edge.y;
            this.selectEdgeType = "numericalToCategorical";
          } else {
            console.log("Categorical to categorical");
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    //--------------------------------
    // x generate fake point
    //--------------------------------
    createRandom() {
      return [faker.datatype.number(), faker.datatype.number()];
    },

    //--------------------------------
    // will be triger after node select
    //--------------------------------
    renderNodeProfile(nodeID) {
      this.nodeSelect = nodeID;
      this.getNodeData(this.nodeSelect);
      this.selectType = "node";
      this.$refs.causal.findNode(nodeID);
    },

    //--------------------------------
    // will be triger after edge select
    //--------------------------------
    renderEdgeProfile(value) {
      this.edgeSource = value.source;
      this.edgeTarget = value.target;
      this.getResponseCurve();
      this.edgeSelect = value.id;
      this.selectType = "edge";
    },

    //--------------------------------
    // get level1 connected node's importance value from target node
    //--------------------------------
    getImportance(importanceArr) {
      this.importanceArr = importanceArr;
    },
    updateImportance() {
      // this.importanceArr.forEach((x) => {
      //   // Find node in this.nodeData by x object key name
      //   let node = this.nodeData[0].analysis.find((element) => {
      //     return element.from === x.source;
      //   });
      //   // console.log("node532");
      //   console.log(node);
      //   // if (node) {
      //   //   if (node.co >= 0) {
      //   //     x.weight = node.imp * 1;
      //   //   } else {
      //   //     x.weight = node.imp * -1;
      //   //   }
      //   // }
      // });

      // this.importanceArr.forEach((x) => {
      //   console.log("x :");
      //   console.log(x);
      // });

      // order importance value
      this.importanceArr.sort(
        (a, b) => Math.abs(b.weight) - Math.abs(a.weight)
      );

      let yAxisNameCategoryArray = this.importanceArr.map((item) => item.name);

      let seriescategoryArray = this.importanceArr.map((item) => {
        if (item.edge_type === "categorical") {
          return Math.abs(item.weight);
        } else {
          return 0;
        }
      });

      let seriesPositiveArray = this.importanceArr.map((item) => {
        if (item.edge_type === "positive") {
          return item.weight;
        } else {
          return 0;
        }
      });

      let seriesNegativeArray = this.importanceArr.map((item) => {
        if (item.edge_type === "negative") {
          return Math.abs(item.weight);
        } else {
          return 0;
        }
      });

      this.$nextTick(() => {
        this.$refs.barNegativeChart.clearData();
        this.$refs.barNegativeChart.addSeries(seriesPositiveArray, "positive");
        this.$refs.barNegativeChart.addSeries(seriesNegativeArray, "negative");
        this.$refs.barNegativeChart.addSeries(seriescategoryArray, "category");
        this.$refs.barNegativeChart.addCategory(yAxisNameCategoryArray);
        this.importanceLength = this.importanceArr.length;
      });
    },

    //--------------------------------
    // note edit
    //--------------------------------
    writeDescription(value) {
      this.description = value;
    },

    //--------------------------------
    // click the node's name in bar-negative-chart will triger the node to the select state
    //--------------------------------
    selectCategory(list, select) {
      list.forEach((elm) => {
        this.$refs.causal.cancelNodeSelect(elm);
      });
      this.$refs.causal.findNode(select);
    },
    onCloseExit() {
      this.dialogExitAlert = false;
    },
    onConfirmExit() {
      this.leave = true;
      this.$router.push(this.nextRoute);
    },
    onCloseReInferConfirmation() {
      this.dialogReInferConfirmation = false;
    },
    onShowDialogReInferConfirmation() {
      this.dialogReInferConfirmation = true;
    },
  },
  beforeRouteLeave(to, from, next) {
    // Set the next route, so we can navigate to it after confirmation
    this.nextRoute = to.path;

    if (this.leave) {
      next();
    } else {
      // Show the dialog
      this.dialogExitAlert = true;

      // Prevent default navigation behavior
      next(false);
    }
  },
  mounted() {
    this.$refs.causal.getData();
  },
  computed: {
    // fake correlation picture
    imageSrc() {
      return this.correlation_picture;
    },
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    options() {
      // const gridlines_color = this.$vuetify.theme.dark
      //   ? "rgba(255, 255, 255, 0.1)"
      //   : "rgba(0, 0, 0, 0.1)";
      const fontColor = this.$vuetify.theme.themes[this.theme].chartFont;
      return {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          labels: {
            fontColor: fontColor,
          },
        },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: false,
                fontColor: fontColor,
                callback: (value) => `${value.toFixed(1)}`, // round to 1 decimal for y-axis values
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                fontColor: fontColor,
                labelString: this.$t("Probability"),
              },
              ticks: {
                beginAtZero: false,
                fontColor: fontColor,
                suggestedMin: 0,
                suggestedMax: 1,
              },
            },
          ],
        },
        elements: {
          line: {
            tension: 0,
          },
        },
      };
    },
    causalPredictionURL() {
      const proj_id = this.$route.params.id
        ? this.$route.params.id
        : this.$route.query.datasetGroupId;
      this.$route.query.paramName;
      return (
        // "http://192.168.50.3:8501/?token=" +
        "/causal_prediction/?token=" +
        localStorage.getItem("accessToken") +
        "&proj_id=" +
        proj_id
      );
    },

    chatbotURL() {
      const proj_id = this.$route.params.id
        ? this.$route.params.id
        : this.$route.query.datasetGroupId;
      this.$route.query.paramName;
      return (
        "/causal_chatbot/?token=" +
        localStorage.getItem("accessToken") +
        "&username=" +
        localStorage.getItem("username") +
        "&proj_id=" +
        proj_id
      );
      // return "http://192.168.50.3/causal_chatbot";
      // return "/causal_chatbot/";
    },
  },
  watch: {
    search(value) {
      this.$refs.causal.findNode(value);
    },
  },
};
</script>

<style scoped>
.drawer_right_Button_open {
  position: absolute;
  top: 50%;
  right: 30%;
}
.drawer_right_Button_close {
  position: absolute;
  top: 50%;
  right: 0%;
}
.scrollable-table {
  max-height: 300px; /* Set the maximum height of the table */
  overflow-y: auto; /* Enable vertical scrolling */
}
</style>
