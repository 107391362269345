var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"-webkit-fill-available"}},[_c('v-app-bar',[_c('v-toolbar-title',[_vm._v("Causal Graph ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"id":"cauasl_graph_guide"}},on),[_vm._v(" mdi-information-outline ")])]}}])},[_vm._v(" Show tour guide ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","placeholder":"Node Name"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('div',{attrs:{"id":"toolbar"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":_vm.chatbotURL,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chat-processing-outline")])],1)]}}])},[_c('span',[_vm._v("Chatbot")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","href":_vm.causalPredictionURL,"target":"_blank"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-creation-outline")])],1)]}}])},[_c('span',[_vm._v("Causal Prediction")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.zoomIn}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify-plus-outline")])],1)]}}])},[_c('span',[_vm._v("Zoom in")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.zoomOut}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify-minus-outline")])],1)]}}])},[_c('span',[_vm._v("Zoom out")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.fitCenter}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-fit-to-page-outline")])],1)]}}])},[_c('span',[_vm._v("Fit center")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.fitView}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-image-filter-center-focus")])],1)]}}])},[_c('span',[_vm._v("Fit view")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.onClickUndo}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-undo")])],1)]}}])},[_c('span',[_vm._v("Undo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.onClickRedo}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-redo")])],1)]}}])},[_c('span',[_vm._v("Redo")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isEdit)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.saveData}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-save")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Save causal graph edition")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.isEdit)?_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.onShowDialogReInferConfirmation}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-play-circle-outline")])],1):_vm._e()]}}])},[_c('span',[_vm._v("Re-infer causal graph")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":_vm.captureImage}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-camera-outline")])],1)]}}])},[_c('span',[_vm._v("Capture image")])])],1)],1),_c('v-sheet',{staticClass:"overflow-hidden",staticStyle:{"position":"relative"},attrs:{"height":"85vh"}},[_c('v-navigation-drawer',{staticClass:"grey lighten-5",attrs:{"app":false,"absolute":"","right":"","width":_vm.barWidth,"mobile-breakpoint":"0"},model:{value:(_vm.drawer_right),callback:function ($$v) {_vm.drawer_right=$$v},expression:"drawer_right"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-expansion-panels',{attrs:{"accordion":"","multiple":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Information_"+_vm._s(this.selectType))]),_c('v-expansion-panel-content',[_c('v-card-text',[(this.selectType == 'node')?_c('div',[_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Features")]),_c('th',{staticClass:"text-left"},[_vm._v("Value")])])]),_c('tbody',_vm._l((_vm.inforArr),function(item){return _c('tr',{key:item.feature},[_c('td',[_vm._v(_vm._s(item.feature))]),_c('td',[_vm._v(_vm._s(item.value))])])}),0)]},proxy:true}],null,false,693411827)}),_c('v-spacer'),(this.uniqueArr !== null)?_c('div',{staticClass:"mt-4 pl-4"},[_vm._v(" Unique ")]):_vm._e(),(_vm.uniqueArr !== null)?_c('v-simple-table',{attrs:{"dense":"","fixed-header":"","height":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Count")])])]),_c('tbody',_vm._l((_vm.uniqueArr),function(o){return _c('tr',{key:o.name},[_c('td',[_vm._v(_vm._s(o.name))]),_c('td',[_vm._v(_vm._s(o.count))])])}),0)]},proxy:true}],null,false,4254960412)}):_vm._e()],1):_vm._e(),(
                    this.selectType == 'edge' &&
                      this.selectEdgeType === 'numericalToNumerical'
                  )?_c('div',[(this.selectType == 'edge')?_c('line-chart',{attrs:{"series":_vm.series,"zoomEnable":false,"titleName":("Correlation   m: " + (this.co)),"xAxisName":_vm.edgeSourceName,"yAxisName":_vm.edgeTargetName,"dateTime":"numeric","height":"300"}}):_vm._e()],1):(
                    this.selectType == 'edge' &&
                      this.selectEdgeType === 'categoricalToNumerical'
                  )?_c('div',[_c('lollipop-chart',{attrs:{"pointData":_vm.lollipopPoints,"xAxisName":_vm.edgeSourceName,"yAxisName":_vm.edgeTargetName}})],1):(
                    this.selectType === 'edge' &&
                      this.selectEdgeType === 'numericalToCategorical'
                  )?_c('div',[_c('MultiLine',{attrs:{"chartData":_vm.multiLines,"options":_vm.options}})],1):(
                    this.selectType == 'edge' && this.selectEdgeType === null
                  )?_c('div',[_vm._v(" No chart available ")]):_vm._e()])],1)],1),(this.selectType == 'node')?_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Note")]),_c('v-expansion-panel-content',[_c('v-card-text',[_c('v-textarea',{attrs:{"outlined":"","height":"100%","name":"input-7-4","label":"Note"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)],1):_vm._e(),(this.selectType == 'node')?_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v("Importance")]),_c('v-expansion-panel-content',[(this.selectType == 'node')?_c('bar-negative-chart',{ref:"barNegativeChart",attrs:{"titleName":"","yAxisName":"","xAxisName":"","height":150 + _vm.importanceLength * 30},on:{"selectCategory":_vm.selectCategory}}):_vm._e()],1)],1):_vm._e()],1)],1)],1),_c('v-btn',{class:_vm.drawer_right
          ? 'drawer_right_Button_open'
          : 'drawer_right_Button_close',attrs:{"color":"primary","fab":"","x-small":"","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.right_drawerChange($event)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.drawer_right_icon)+" ")])],1),_c('v-container',{attrs:{"fluid":""}},[_c('causal-chart',{ref:"causal",attrs:{"nodeSelect":_vm.nodeSelect,"description":_vm.description},on:{"renderFinish":_vm.getuploadState,"targetNode":_vm.renderNodeProfile,"targetNodeDesc":_vm.writeDescription,"targetEdge":_vm.renderEdgeProfile,"nodeImportance":_vm.getImportance}})],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogExitAlert),callback:function ($$v) {_vm.dialogExitAlert=$$v},expression:"dialogExitAlert"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Exit the Causal Discovery Editor?")])]),_c('v-card-text',[_vm._v(" If you modify the graph, please remember to save the latest edition. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onCloseExit}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onConfirmExit}},[_vm._v(" Exit ")])],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.dialogReInferConfirmation),callback:function ($$v) {_vm.dialogReInferConfirmation=$$v},expression:"dialogReInferConfirmation"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Re-infer Causal Graph")])]),_c('v-card-text',[_vm._v(" Save the latest model before re-inferring the causal graph. The process may take a few minutes for the results. ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.onCloseReInferConfirmation}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.generateGraph}},[_vm._v(" Re-infer ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }