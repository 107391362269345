<template>
  <div class="example">
    <apex-chart
      ref="chart"
      type="line"
      :height="height"
      :width="width"
      :options="chartOptions"
      :series="series"
    ></apex-chart>
  </div>
</template>

<script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";

Vue.use(VueApexCharts);
Vue.component();
// import { faker } from "@faker-js/faker";
export default {
  components: {
    "apex-chart": VueApexCharts,
  },
  props: {
    isDetail: {
      type: Boolean,
      default: true,
    },
    zoomEnable: {
      type: Boolean,
      default: true,
    },
    toolBarShow: {
      type: Boolean,
      default: false,
    },
    titleName: {
      type: String,
      default: "lineChart",
    },
    xAxisName: {
      type: String,
      default: "xAxis",
    },
    yAxisName: {
      type: String,
      default: "yAxis",
    },
    height: {
      default: "auto",
    },
    width: {
      default: "100%",
    },
    tickAmount: {
      default: 1,
    },
    series: {
      type: Array,
      default() {
        return [];
      },
    },
    dataType: {
      type: String,
      default: "datetime",
    },
    categories: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      chartOptions: {},
    };
  },
  methods: {
    getData() {},
    setChartConfig() {
      this.chartOptions = {
        chart: {
          toolbar: {
            show: false,
          },
          height: 350,
          type: "line",
          zoom: {
            enabled: true,
          },
          animations: {
            enabled: false,
            speed: 500,
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: "",
          align: "left",
        },
        markers: {
          size: 3,
          hover: {
            sizeOffset: 6,
          },
          strokeWidth: 0,
        },
        grid: {
          show: false,
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
        },
        xaxis: {
          type: this.dataType,
          tickAmount: this.tickAmount,
          title: {
            text: this.xAxisName,
            offsetY: 0,
          },
          position: "bottom",
          categories: this.categories,
          // labels:{
          //   datetimeUTC: true,
          //   datetimeFormatter: {
          //     year: 'yyyy',
          //     month: "MMM 'yy",
          //     day: 'dd MMM',
          //     hour: 'HH:mm',
          // },
          // }
          labels: {
            formatter: function(value) {
              if (typeof value === "number") {
                if (Math.abs(value) >= 1e6) {
                  // Convert to scientific notation if value exceeds 6 digits
                  return value.toExponential(1);
                }
                return value.toFixed(1);
              } else {
                return value;
              }
            },
          },
        },
        yaxis: {
          type: "numeric",
          title: {
            text: this.yAxisName,
          },
          labels: {
            formatter: function(value) {
              if (typeof value === "number") {
                if (Math.abs(value) >= 1e6) {
                  // Convert to scientific notation if value exceeds 6 digits
                  return value.toExponential(1);
                }
                return value.toFixed(1);
              } else {
                return value;
              }
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val;
            },
          },
        },
      };
    },
    changeStyle(isDetail, toolBarShow, zoomEnable) {
      this.chartOptions.chart.toolbar.show = toolBarShow;
      this.chartOptions.chart.zoom.enabled = zoomEnable;
      this.chartOptions.xaxis.show = isDetail;
      this.chartOptions.yaxis.show = isDetail;
      this.chartOptions.grid.show = isDetail;
      if (isDetail == false) {
        this.chartOptions.xaxis.title.text = "";
        this.chartOptions.title.text = "";
        this.chartOptions.grid.row.colors = undefined;
        this.chartOptions.grid.column.colors = undefined;
      }
    },
    setXCategory(categories, dateType, tickAmount) {
      this.chartOptions.xaxis.type = dateType;
      this.chartOptions.xaxis.categories = categories;
      this.chartOptions.xaxis.tickAmount = tickAmount;
    },
    setChartInfo(titleName, xAxisName, yAxisName) {
      this.chartOptions.title.text = titleName;
      this.chartOptions.xaxis.title.text = xAxisName;
      this.chartOptions.yaxis.title.text = yAxisName;
    },
    update() {
      this.$refs.chart.updateOptions({
        title: {
          text: this.titleName,
        },
        xaxis: {
          tickAmount: this.tickAmount,
          title: {
            text: this.xAxisName,
          },
          labels: {
            formatter: function(value) {
              if (typeof value === "number") {
                if (Math.abs(value) >= 1e6) {
                  // Convert to scientific notation if value exceeds 6 digits
                  return value.toExponential(1);
                }
                return value.toFixed(1);
              } else {
                return value;
              }
            },
          },
        },
        yaxis: {
          title: {
            text: this.yAxisName,
          },
          labels: {
            formatter: function(value) {
              if (typeof value === "number") {
                if (Math.abs(value) >= 1e6) {
                  // Convert to scientific notation if value exceeds 6 digits
                  return value.toExponential(1);
                }
                return value.toFixed(1);
              } else {
                return value;
              }
            },
          },
        },
      });
    },
    addSeries(name, data) {
      this.$refs.chart.appendSeries({
        name: name,
        data: data,
      });
    },
    setTitle(title) {
      this.chartOptions.title.text = title;
    },
    // createRandom() {
    //   return {
    //     x: faker.date.betweens('2020-01-01T00:00:00.000Z', '2030-01-01T00:00:00.000Z',1),
    //     y: faker.datatype.number(),
    //   };
    // },
  },
  mounted() {},
  created() {
    this.setChartConfig();
    this.setChartInfo(this.titleName, this.xAxisName, this.yAxisName);
    this.changeStyle(this.isDetail, this.toolBarShow, this.zoomEnable);
    this.setXCategory(this.categories, this.dateType, this.tickAmount);
  },
  watch: {
    titleName() {
      this.update();
    },
    xAxisName() {
      this.update();
    },
    yAxisName() {
      this.update();
    },
  },
};
</script>
